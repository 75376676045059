import React, { Component } from 'react'

class Thanks extends Component {


  render() {

    return (
      <>

      <div className = "thankyoupage">

      <div className = "message">

      <h5>      你的舍己奉獻，建造屬神教會 </h5> <br />

      <p>
      <h5>

      那赐种给撒种的，赐粮给人吃的，必多多加给你们种地的种子,

      </h5>
      </p>
      <p>
      <h5>
      又增添你们仁义的果子，叫你们凡事富足。 (哥林多后书 9:10–11)
      </h5>
      </p>

      <br />
      <p>
      <h5>
      馬利蘭華人浸信會 <br />
      240-481-1600
      </h5>
      </p>

      </div>

      </div>

      </>


    )


  }

}


export default Thanks;
