import React, { Component } from 'react';
import fire from '../fire.js';
export class AnnouncementForm extends Component {
    constructor() {
      super();
      this.state = {
        title: "",
        body: "",
        date: new Date(),
      }
    }

    render() {

      return (
        <center>
        <div className="login">

            <form onSubmit={this.submitAnnouncement}>
                <h3>Title:</h3>
                <input type="text" name="title"  value={this.state.title} onChange={this.handleChange}/>
                <br/>
                <h3>Body:</h3>
                <textarea name = "body" value={this.state.body} onChange={this.handleChange} rows="4" cols="50"/>

                <br/>
                <button type="submit" onClick={this.submitAnnouncement}> Submit </button>
            </form>
        </div>
        </center>
      );
    }

    submitAnnouncement = (event) => {
      event.preventDefault();
      console.log("you should be in form");
      var db = fire.firestore();


      db.collection("Announcements").add({title: this.state.title, body: this.state.body, date: this.state.date });
    }


    handleChange = (e) => {
        const name = e.target.name
        this.setState({[name]: e.target.value});
      }

}

  export default AnnouncementForm;
