import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"

class Discipleship extends Component {


  render() {
    return (
        <>
            {/* <UploadPhoto storageName = "discipleship-photos" collectionName = "DiscipleshipPhotos" user={this.props.user}/> */}

            <div className = "sectionPart" id = "d">
                <center> <h3>  门徒训练 </h3> </center>  因着神话语的装备和圣灵赐下的能力，弟兄姐妹不单被挑战过好得胜的生活，更是被呼召在自己的亲人朋友，同事邻居中分享在主耶稣基督里得救的盼望和喜乐，带领他们信靠主耶稣，同蒙主恩，成为主的门徒。
                {/* <DisplayPhotos collectionName = "DiscipleshipPhotos" /> */}

            </div>
            <div className = "keywords">
              马利兰
              马里兰
              马利兰教会
              马利兰华人教会
              马利兰浸信会
              马利兰华人浸信会
              马里兰教会
              马里兰华人教会
              马里兰浸信会
              马里兰华人浸信会
              华人浸信会
              华人教会
            </div>


        </>



    )


  }

}


export default Discipleship;
