import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"

class Fellowship extends Component {

  render() {

    return (
      <>


      <div className = "keywords">
      马利兰
      马里兰
      马利兰教会
      马利兰华人教会
      马利兰浸信会
      马利兰华人浸信会
      马里兰教会
      马里兰华人教会
      马里兰浸信会
      马里兰华人浸信会
      华人浸信会
      华人教会
      </div>

      {/* <UploadPhoto storageName = "fellowship-photos" collectionName = "FellowshipPhotos" user={this.props.user}/> */}

      <div className = "sectionPart" id = "e">
          <center> <h3> 团契交谊 </h3> </center> 教会是个属灵的家，每位弟兄姐妹个人信仰生命的满足和追求，都需要其他人。来到这里你能感受到弟兄姐妹们的热情和在主里的爱心，教会鼓励你进入一个团契，融入弟兄姐妹互为属灵肢体的团契生活。
          {/* <DisplayPhotos collectionName = "FellowshipPhotos" /> */}
      </div>


      </>


    )


  }

}


export default Fellowship;
