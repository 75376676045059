// import React, { Component } from "react";
// // import FileUploader from "react-firebase-file-uploader";
// import fire from '../fire.js';

// class SermonUpload extends Component {
//   state = {
//     filenames: [],
//     downloadURLs: [],
//     isUploading: false,
//     uploadProgress: 0
//   };

//   handleUploadStart = () =>
//     this.setState({
//       isUploading: true,
//       uploadProgress: 0
//     });

//   handleProgress = progress =>
//     this.setState({
//       uploadProgress: progress
//     });

//   handleUploadError = error => {
//     this.setState({
//       isUploading: false
//       // Todo: handle error
//     });
//     console.error(error);
//   };

//   handleUploadSuccess = async filename => {
//     const downloadURL = await fire
//       .storage()
//       .ref("audio")
//       .child(filename)
//       .getDownloadURL();

//     this.setState(oldState => ({
//       filenames: [...oldState.filenames, filename],
//       downloadURLs: [...oldState.downloadURLs, downloadURL],
//       uploadProgress: 100,
//       isUploading: false
//     }));
//     var db = fire.firestore();

//     db.collection("AudioFileNames").add({url: downloadURL,
//     name: filename})
//   };

//   render() {
//     return (
//       <div className = "content">
//         <FileUploader
//           accept="audio/*"
//           name="image-uploader-multiple"
//           storageRef={fire.storage().ref("audio")}
//           onUploadStart={this.handleUploadStart}
//           onUploadError={this.handleUploadError}
//           onUploadSuccess={this.handleUploadSuccess}
//           onProgress={this.handleProgress}
//           multiple
//         />

//         <p>Progress: {this.state.uploadProgress}</p>

//         <p>Filenames: {this.state.filenames.join(", ")}</p>

//         <div>
//           {this.state.downloadURLs.map((downloadURL, i) => {
//             return <img key={i} alt ={i} src={downloadURL} />;
//           })}
//         </div>

//       </div>
//     );
//   }
// }

// export default SermonUpload;
