import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"


class Welcome extends Component {

  render() {

    return (
      <>
      {/* <UploadPhoto storageName = "welcome-photos" collectionName = "WelcomePhotos" user={this.props.user}/> */}


      <div className = "keywords">
      马利兰
      马里兰
      马利兰教会
      马利兰华人教会
      马利兰浸信会
      马利兰华人浸信会
      马里兰教会
      马里兰华人教会
      马里兰浸信会
      马里兰华人浸信会
      华人浸信会
      华人教会
      </div>

      <div className = "sectionPart" id = "g">

        <center> <h3>  邀请您和我们一起事奉主 (You are Invited to Serve the Lord with Us) </h3> </center> 如果你有感动，愿意参与教会中的服侍。教会欢迎你加入我们的服侍团队，请你告诉我们你的服侍恩赐，和你对哪些事工有负担，愿意付出。
        {/* <DisplayPhotos collectionName = "WelcomePhotos"/> */}


      </div>


      </>


    )


  }

}


export default Welcome;
