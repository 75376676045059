import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"


class LunchFellowship extends Component {

  render() {
    return (
      <>

      <div className = "keywords">
      马利兰
      马里兰
      马利兰教会
      马利兰华人教会
      马利兰浸信会
      马利兰华人浸信会
      马里兰教会
      马里兰华人教会
      马里兰浸信会
      马里兰华人浸信会
      华人浸信会
      华人教会
      </div>


      <div className = "sectionPart" id = "f">
      {/* <UploadPhoto storageName = "lunchfellowship-photos" collectionName = "LunchFellowshipPhotos" user={this.props.user}/> */}

      <center> <h3>  教会午餐 </h3> </center> 主日崇拜后的午餐時間是弟兄姐妹在主里彼此交流和關愛的好時間。 教會歡迎你崇拜后留下和我們一起午餐。除了每月最後一個主日午餐是愛筵時間外，其他主日午餐都是分別從中國餐館和Pizza店訂購食物。每當愛筵時間，一家帶一菜來與大家分享。你將享受到百家宴的美食。
      {/* <DisplayPhotos collectionName = "LunchFellowshipPhotos" /> */}
      </div>



      </>


    )


  }

}


export default LunchFellowship;
