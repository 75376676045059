import React, { Component } from 'react'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'
import gallery1 from '../images/gallery1.jpg'
import gallery2 from '../images/gallery2.jpg'
import gallery3 from '../images/gallery3.png'
import gallery4 from '../images/gallery4.jpg'
import gallery5 from '../images/gallery5.jpeg'



class Home extends Component {


  render() {

    return (
      <>
      <div className = "welcomebox" >

        <center> <h2>   欢迎你浏览我们的网站!  </h2> </center>
        <div style = {{align: "center",margin: "auto" , padding: "1%", width: "50%", "fontSize":"calc(5px + 2vmin)" }} >
        <center> <div style = {{"fontSize": ".75em", "marginTop": "0px"}}>        <h5>  主日敬拜: 10:00am, 主日学: 11:20am </h5> 
    </div>  </center> <br/>

          现代人宣称有人生的自由, <br />

          但“肉体的情欲，眼目的情欲，今生的骄傲”

          仍是摆脱不了的心灵枷锁。 <br />

          耶稣说，“你们若常常遵守我的道，就真是我的门徒， <br />

          你们必晓得真理，真理必叫你们得以自由”。 <br /><br />

          马利兰华人浸信会真诚邀请你,<br />

          来经历主耶稣无价的恩典, <br />

          身心灵得以完全释放，

          成为真正自由的人。
          <br />
           <center>  <p> 陈翔牧师 </p></center>

          </div>



          <center>

         <p>
          </p> </center>
      </div>

      <div className = "contentleft">
      <h5> 實體崇拜地址：</h5>  
      <p> Viers Mill Baptist Church, Fellowship Hall       <br /> 12221 Veirs Mill Rd, Silver Spring, MD 20906 </p> <br /> 線上Zoom 直播崇拜網址：<a href = "https://zoom.us/j/2357268372"> https://zoom.us/j/2357268372</a> <br />房间号(meeting ID): 2357268372 <br /> 密碼: mdcbc <br /><br />
       <h5>  主日敬拜: 10:00am  </h5>   <h5>  主日学: 11:20am </h5> 
      <p><a href={"mailto: mdchinesebc@gmail.com"}> mdchinesebc@gmail.com </a> </p>
      <p> 240-481-1600 </p>

      <br />
      <br />


      </div>

      <div className = "contentright">
      <iframe  id = "homePhoto" title = "homePhoto" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3098.1057927425313!2d-77.08757018502462!3d39.05850317954612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7ce63d7059f9f%3A0x57d32168edb34206!2s12221%20Veirs%20Mill%20Rd%2C%20Silver%20Spring%2C%20MD%2020906!5e0!3m2!1sen!2sus!4v1678137077119!5m2!1sen!2sus" style={{border:"0", width: "100%", height: "100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

<center>

<Carousel style = {{"marginTop": "5em", "marginBottom": "5em"}}>
        <Carousel.Item>
          <img
            className="d-block w-80"
            src={gallery1}
            alt="First slide"
          />

        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-80"
            src={gallery2}
            alt="Third slide"
          />


        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-80"
            src={gallery3}
            alt="Third slide"
          />


        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-80"
            src={gallery4}
            alt="Third slide"
          />


        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-80"
            src={gallery5}
            alt="Third slide"
          />


        </Carousel.Item>
      </Carousel>
</center>




      <div className = "keywords">
      马利兰
      马里兰
      马利兰教会
      马利兰华人教会
      马利兰浸信会
      马利兰华人浸信会
      马里兰教会
      马里兰华人教会
      马里兰浸信会
      马里兰华人浸信会
      华人浸信会
      华人教会
      </div>

      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
      </>


    )


  }

}


export default Home;
