// import React, { Component } from 'react'
// import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import FileUploader from "react-firebase-file-uploader";
// import fire from '../../../fire.js';

// class UploadPhoto extends Component {

//     state = {
//       filenames: [],
//       downloadURLs: [],
//       isUploading: false,
//       uploadProgress: 0
//     };

//     handleUploadStart = () =>
//       this.setState({
//         isUploading: true,
//         uploadProgress: 0
//       });

//     handleProgress = progress =>
//       this.setState({
//         uploadProgress: progress
//       });

//     handleUploadError = error => {
//       this.setState({
//         isUploading: false
//         // Todo: handle error
//       });
//       console.error(error);
//     };

//     handleUploadSuccess = async filename => {
//       const downloadURL = await fire
//         .storage()
//         .ref(this.props.storageName)
//         .child(filename)
//         .getDownloadURL();

//       this.setState(oldState => ({
//         filenames: [...oldState.filenames, filename],
//         downloadURLs: [...oldState.downloadURLs, downloadURL],
//         uploadProgress: 100,
//         isUploading: false
//       }));
//       var db = fire.firestore();

//       db.collection(this.props.collectionName).add({url: downloadURL,
//       name: filename}).then(function(docRef) {      })
//         .catch(function(error) {
//             console.error("Error adding document: ", error);
//         });
//     };



//       render() {


//         return (
//           <>
//           {this.props.user ?
//             <>
//             <center>

//             <div className = "content">
//               <FileUploader
//                 accept="image/*"
//                 name="image-uploader-multiple"
//                 storageRef={fire.storage().ref(this.props.storageName)}
//                 onUploadStart={this.handleUploadStart}
//                 onUploadError={this.handleUploadError}
//                 onUploadSuccess={this.handleUploadSuccess}
//                 onProgress={this.handleProgress}
//                 multiple
//               />

//               <p>Progress: {this.state.uploadProgress}</p>

//             </div>

//             </center>




//             </>
//           :
//             <> </>
//     }
//     </>
//         )

//       }


// }
// export default UploadPhoto;
