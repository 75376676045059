import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"


class YouthMinistry extends Component {

  render() {

    return (
      <>
            {/* <UploadPhoto storageName = "youthministry-photos" collectionName = "YouthMinistryPhotos" user={this.props.user}/> */}
            <div className = "keywords">
            马利兰
            马里兰
            马利兰教会
            马利兰华人教会
            马利兰浸信会
            马利兰华人浸信会
            马里兰教会
            马里兰华人教会
            马里兰浸信会
            马里兰华人浸信会
            华人浸信会
            华人教会
            </div>

            <div className = "sectionPart" id = "b">

              <p>
              <center> <h3> 青少年事工  </h3> </center>
              青少年儿童事工，目前注重主日聚会。 青少年由将要从Carnegie Mellon University毕业的年轻姐妹带领。有全体青少年儿童聚集的敬拜赞美祷告，也有按年龄的分小组圣经学习。
              </p>
              {/* <DisplayPhotos collectionName = "YouthMinistryPhotos" /> */}
              </div>

      </>


    )


  }

}


export default YouthMinistry;
