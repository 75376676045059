// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  font-size: 1.5em !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.Carousel {
  width: 100% !important
}

.Card {
  font-size: 1.5em !important;
}

/* .thankyoupage {
  background-color: #FCF4A3;
  height: 100%;
  width: 100%;
} */

.message {
  margin-left: 25%;
  margin-top: 3em;
  background-color:  #FCF4A3;
  padding: 5em;
  width: 45%;
  line-height: 1em;
  height: 30%;

}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.keywords {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,2BAA2B;AAC7B;;AAEA;EACE,4CAA4C;EAC5C,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;AACA;EACE;AACF;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;;;;GAIG;;AAEH;EACE,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,WAAW;;AAEb;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,YAAY;AACd","sourcesContent":[".App {\n  text-align: center;\n  font-size: 1.5em !important;\n}\n\n.App-logo {\n  animation: App-logo-spin infinite 20s linear;\n  height: 40vmin;\n  pointer-events: none;\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n.Carousel {\n  width: 100% !important\n}\n\n.Card {\n  font-size: 1.5em !important;\n}\n\n/* .thankyoupage {\n  background-color: #FCF4A3;\n  height: 100%;\n  width: 100%;\n} */\n\n.message {\n  margin-left: 25%;\n  margin-top: 3em;\n  background-color:  #FCF4A3;\n  padding: 5em;\n  width: 45%;\n  line-height: 1em;\n  height: 30%;\n\n}\n\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.keywords {\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
