// import React, { Component } from 'react'
// import '@cassette/player/dist/css/cassette-player.css';

// import fire from '../fire.js';
// import { MediaPlayer } from '@cassette/player';

// import {Card} from 'react-bootstrap';
// import Accordion from 'react-bootstrap/Accordion'

// class AudioList extends Component {
//   constructor() {
//     super();

//     this.state = {
//       options: [],
//       currPlaying: [],

//     };
//   }

//   changeAudio = (e, i) => {
//     e.preventDefault();
//     var res = [this.state.options[i] ];
//     // console.log(res.concat(this.state.options));
//     // for (var j = 0; j < this.state.options.length; j++) {
//     //   if (j != i) {
//     //     res = res.concat(this.state.options[j]);
//     //   }
//     // }
//     this.setState({currPlaying:res })
//   }


//   componentDidMount() {

//     var db = fire.firestore();
//     db.collection("AudioFileNames").get().then((querySnapshot) => {
//         querySnapshot.forEach((doc) => {
//             this.setState({options: this.state.options.concat( {url: doc.data().url, title: doc.data().name}  )})
//          });
//          this.setState({currPlaying: this.state.options})
//     });

//   }



//   render() {
//     let playlist = [];
//     for (let i in this.state.options)  {
//       playlist.push(
//         <li key={i} onClick={e => this.changeAudio(e, i)}>
//           <div>{this.state.options[i].title}</div>
//         </li>
//       );

//     }


//     return (

//       <div className = "content">
//       <center> <h3> Featured Sermon Videos | 夏令会系列讲道 </h3> </center>

//       <Accordion>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="0">
//             CCB Sermon 1
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="0">
//             <Card.Body><iframe width="100%" height="750" title = "CCB Sermon 1" src="https://www.youtube.com/embed/1VC4T1QAgiU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></Card.Body>
//           </Accordion.Collapse>
//         </Card>
//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="1">
//             CCB Sermon 2
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="1">
//             <Card.Body><iframe width="100%" height="750"  title = "CCB Sermon 2" src="https://www.youtube.com/embed/1N4bgysslII" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></Card.Body>
//           </Accordion.Collapse>
//         </Card>

//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="2">
//             CCB Sermon 3
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="2">
//             <Card.Body>
//                 <iframe width="100%" height="750"   title = "CCB Sermon 3" src="https://www.youtube.com/embed/q3H0xReqjF8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
//             </Card.Body>
//           </Accordion.Collapse>
//         </Card>


//         <Card>
//           <Accordion.Toggle as={Card.Header} eventKey="3">
//             CCB Sermon 4
//           </Accordion.Toggle>
//           <Accordion.Collapse eventKey="3">
//             <Card.Body>
//               <iframe width="100%" height="750"   title = "CCB Sermon 4" src="https://www.youtube.com/embed/hLUWoDV0sZ4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
//             </Card.Body>
//           </Accordion.Collapse>
//         </Card>
//       </Accordion>


//         <center> <h3> Other Audio Sermons | 讲道录音 </h3> </center>
//         <div className = "playListDisplay">
//           <ul > {playlist} </ul>
//         </div>


//         <div className = "audioplayer">
//         <MediaPlayer
//           playlist={this.state.currPlaying}
//           controls={	[ 'spacer', 'backskip', 'playpause', 'forwardskip', 'volume','spacer', 'progress'  ]}
//           defaultRepeatStrategy="playlist"
//         />
//         </div>




//       </div>
//     )
//   }
// }


// export default AudioList;
