import React, { Component } from 'react';
import fire from '../fire.js';
import { Card } from 'react-bootstrap';


export class Announcement extends Component {
    constructor() {
      super();
      this.state = {
        options: []
      }
    }

    componentDidMount() {

      var db = fire.firestore();
      db.collection("Announcements").get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
              this.setState({options: this.state.options.concat( {title: doc.data().title, body: doc.data().body, date: doc.data().date.toDate()+"",uid: doc.id }  )})
           });
      });

      this.setState({currPlaying: this.state.options})
    }


    render() {

      let playlist = [];
      console.log(this.state.options);

      for (let i in this.state.options)  {
        playlist.push(
          <div style = {{width: "90%", margin: "1%", align: "center", "font-size": "1.5em"}} >

          <Card>
            <Card.Body>

              <Card.Title>  <div style =  {{"font-size": "1.5em"}} >
                  {this.state.options[i].title} </div> </Card.Title>

              <Card.Text>
                 {this.state.options[i].body}
               </Card.Text>

               <footer >
                {this.state.options[i].date}
                {this.props.user ?
                  <>
                  <button onClick={this.delete.bind(this, this.state.options[i].uid)} class="btn btn-danger">Delete</button>
                  </>
                :
                  <>

                  </>
                }
              </footer>

            </Card.Body>
          </Card>

          </div>

        );

      }

      return (
        <div className="login">
        <div style = {{ overflow: "scroll", "margin-left": "5%", "margin-bottom": "5%"}}>

        <ul > {playlist}</ul>

        </div>
        </div>



      );
    }

    delete(id){
      fire.firestore().collection('Announcements').doc(id).delete().then(() => {
        console.log("Document successfully deleted!");
      }).catch((error) => {
        console.error("Error removing document: ", error);
      });
    }

    submitAnnouncement = (event) => {
      event.preventDefault();
      var db = fire.firestore();
      db.collection("Announcements").add({title: this.state.title, body: this.state.body, date: this.state.date });
    }


    handleChange = (e) => {
        const name = e.target.name
        this.setState({[name]: e.target.value});
      }

}

  export default Announcement;
