import React, { Component } from 'react';
import fire from '../fire.js';

export class Login extends Component {
    constructor() {
      super();
      this.state = {
        email: "",
        password: "",
        found: true,
      }
    }

    render() {

      return (
        <center>
        <div className="login">
                {this.state.found === true ?
                <div></div>
                :
                <h1> Could not find an account with that email/password.</h1>
                }
            <form onSubmit={this.login}>
                <h3>Email:</h3>
                <input type="text" name="email" autoComplete="email" value={this.state.email} onChange={this.handleChange}/>
                <br/>
                <h3>Password:</h3>
                <input type="password" name="password" autoComplete="password" value={this.state.password} onChange={this.handleChange}/>
                <br/><br/> <br/> <br/>

                <button type="submit" id="loginbutton" onClick={this.login}> Log In</button>
            </form>
        </div>
        </center>
      );
    }

    login = (e) => {
      e.preventDefault();
      fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
      }).catch((error) => {
          this.setState({
              found: false
          })
        });
    }

    onClickButton = (event) => {
      event.preventDefault();
      this.props.login(null, this.state.email.toString(), this.state.password.toString())
    }

    handleChange = (e) => {
        const name = e.target.name
        this.setState({[name]: e.target.value});
      }

}

  export default Login;
