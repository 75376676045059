import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

var config = {
   apiKey: "AIzaSyDBQfOOhWjhaCeTUSaaK4SagB8f6iCg0S8",
   authDomain: "mdchinesechurch.firebaseapp.com",
   databaseURL: "https://mdchinesechurch.firebaseio.com",
   projectId: "mdchinesechurch",
   storageBucket: "mdchinesechurch.appspot.com",
   messagingSenderId: "181235990303",
   appId: "1:181235990303:web:9c1410736b5d1aed"
 };

const fire = firebase.initializeApp(config);
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
export default fire;
