import React, { Component } from 'react';
import {Route} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Link } from 'react-router-dom';

import './App.css';
import fire, { auth } from './fire.js';
import SermonUpload from "./components/uploadaudio.js";
import AudioList from "./components/audioplayerlist.js";
import Home from "./components/home.js";

import Login from "./components/login.js";
import Announcement from "./components/announcements.js";
import AnnouncementForm from "./components/announcementsform.js";
import Calendar from "./components/calendar.js";
import {NavDropdown} from 'react-bootstrap';

import Thanks from "./components/thankyou.js"
import mdchineselogo from './images/mdchineselogo.png'


import Discipleship from './components/ministry/discipleship.js'
import LunchFellowship from './components/ministry/lunchfellowship.js'
import SundaySchool from './components/ministry/sundayschool.js'
import SundayWorship from './components/ministry/sundayworship.js'
import Welcome from './components/ministry/welcome.js'
import YouthMinistry from './components/ministry/youthministry.js'
import Fellowship from './components/ministry/fellowship.js'

class App extends Component {
  constructor() {
    super();
    this.state = {
      user: null,
      currRole: "GUEST",
      show: false,
      email: "",
      password: "",
      found: true,
      redirect: false
    }
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }


  onClickButton = (event) => {
    event.preventDefault();
    this.props.login(null, this.state.email.toString(), this.state.password.toString())
  }

  handleChange = (e) => {
      const name = e.target.name
      this.setState({[name]: e.target.value});
    }
    login = (e) => {
      e.preventDefault();
      fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((u)=>{
        this.setState({
          redirect: true
        })
      }).catch((error) => {
          this.setState({
              found: false
          })
        });
    }


  logout() {
    auth.signOut()
      .then(() => {
        this.setState({
          user: null
        });
      });
  }




  componentDidMount() {
    auth.onAuthStateChanged((user) => {

      if (user) {
        this.setState({ user: user });
      }

      var db = fire.firestore();

      if (this.state.user) {
          db.collection("Users").doc(this.state.user.uid).get().then(doc =>
           this.setState({ currRole: doc.data().role })
       );

      }
      else if (this.state.user == null) {
        this.setState({currRole: "GUEST"})
      }
    });

  }

  render() {
    console.log(this.state.currRole);

    return (
      <div className='app'>
      <div className = "logo">

      <a href = "/home" > <img src={mdchineselogo} alt="Logo" height="80" /> </a>

      </div>

      <div id = "paypalform">
      ..捐得乐意的人是神所喜爱的..--林后9:7
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
      <input type="hidden" name="cmd" value="_s-xclick" />
      <input type="hidden" name="hosted_button_id" value="WET5KF6NNS7UE" />
      <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
      <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
      </form>
      </div>

      <Router>



        <header>

            <div className="wrapper">

              {this.state.user ?


                <nav className="navbar navbar-expand-lg ">
                  <ul className="navbar-nav mr-auto">
                      <li><Link to={'/home'} className="nav-link navbarx"> Home 主页</Link></li>
                      <NavDropdown title="Ministry 事工" className="dropdown">
                       <NavDropdown.Item href="/ministry/sundayworship">主日崇拜 | Sunday Worship</NavDropdown.Item>
                       <NavDropdown.Item href="/ministry/sundayschool">主日学 | Sunday School</NavDropdown.Item>
                       <NavDropdown.Item href="/ministry/youthministry">青少年事工 | Youth and Children Ministry</NavDropdown.Item>
                       <NavDropdown.Item href="/ministry/discipleship">门徒训练 | Discipleship </NavDropdown.Item>
                       <NavDropdown.Item href="/ministry/fellowship">团契交谊 | Fellowship </NavDropdown.Item>
                       <NavDropdown.Item href="/ministry/lunchfellowship">教会午餐 |  Lunch Fellowship</NavDropdown.Item>
                       <NavDropdown.Item href="/ministry/welcome">邀请您 |  Welcome </NavDropdown.Item>
                     </NavDropdown>
                      <li><Link to={'/calendar'} className="nav-link navbarx"> Calendar 活动 </Link></li>
                      <li><Link to={'/announcements'} className="nav-link navbarx"> Announcement 通知 </Link></li>
                      <li><Link to={'/announcements-upload'} className="nav-link navbarx"> Announcement Form </Link></li>

                      {/* <li><Link to={'/sermons'} className="nav-link navbarx"> Sermons 讲道 </Link></li> */}
                      <li><Link to={'/sermon-upload'} className="nav-link navbarx"> Upload Sermon </Link></li>
                      <li className="nav-link navbarx" onClick={this.logout}> Log out </li>

                  </ul>

                </nav>
              :
              <>
              <nav className="navbar navbar-expand-lg ">
                <ul className="navbar-nav mr-auto">
                    <li><Link to={'/home'} className="nav-link navbarx"> Home 主页</Link></li>
                    <NavDropdown title="Ministry 事工" className="dropdown">
                     <NavDropdown.Item href="/ministry/sundayworship">主日崇拜 | Sunday Worship</NavDropdown.Item>
                     <NavDropdown.Item href="/ministry/sundayschool">主日学 | Sunday School</NavDropdown.Item>
                     <NavDropdown.Item href="/ministry/youthministry">青少年事工 | Youth and Children Ministry</NavDropdown.Item>
                     <NavDropdown.Item href="/ministry/discipleship">门徒训练 | Discipleship </NavDropdown.Item>
                     <NavDropdown.Item href="/ministry/fellowship">团契交谊 | Fellowship </NavDropdown.Item>
                     <NavDropdown.Item href="/ministry/lunchfellowship">教会午餐 |  Lunch Fellowship</NavDropdown.Item>
                     <NavDropdown.Item href="/ministry/welcome">邀请您 |  Welcome </NavDropdown.Item>
                   </NavDropdown>
                    <li><Link to={'/calendar'} className="nav-link navbarx"> Calendar 活动 </Link></li>
                    {/* <li><Link to={'/announcements'} className="nav-link navbarx"> Announcement 通知 </Link></li> */}
                    {/* <li><Link to={'/sermons'} className="nav-link navbarx"> Sermons 讲道 </Link></li> */}
                </ul>

              </nav>

               <Modal show={this.state.show} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                  </Modal.Header>
                  <Modal.Body> <div className="login">
                  {this.state.found === true ?
                  <div></div>
                  :
                  <h1> Could not find an account with that email/password.</h1>
                  }
              <form onSubmit={this.login}>
                  <h3>Email:</h3>
                  <input type="text" name="email" autoComplete="email" value={this.state.email} onChange={this.handleChange}/>
                  <br/>
                  <h3>Password:</h3>
                  <input type="password" name="password" autoComplete="password" value={this.state.password} onChange={this.handleChange}/>
                  <br/><br/> <br/> <br/>


              </form>
          </div> </Modal.Body>
          <Modal.Footer>
                <button type="submit" id="loginbutton" onClick={this.login}> Log In</button>

          </Modal.Footer>
        </Modal>
        </>
              }
            </div>
        </header>
        {this.state.user ?
          <>
          <Routes>
          <Route path='/home' element={<Home />} />
          <Route path='/sermon-upload' element={<SermonUpload />} />
          {/* <Route path='/sermons' element={<AudioList />} /> */}
          {/* <Route path='/announcements-upload' element={<AnnouncementForm />} /> */}
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/thankyou' element={<Thanks />} />

              {/* <Route
                  path='/announcements'
                  component={() => <Announcement user={this.state.user} />}
                /> */}
            <Route path='/ministry/discipleship' element={ <Discipleship user={this.state.user} />} />
            <Route path='/ministry/fellowship' element={ <Fellowship user={this.state.user} />} />
            <Route path='/ministry/lunchfellowship' element={ <LunchFellowship user={this.state.user} />} />
            <Route path='/ministry/sundayschool' element={ <SundaySchool user={this.state.user} />} />
            <Route path='/ministry/sundayworship' element={ <SundayWorship user={this.state.user} />} />
            <Route path='/ministry/welcome' element={ <Welcome user={this.state.user} />} />
            <Route path='/ministry/youthministry' element={ <YouthMinistry user={this.state.user} />} />
              
            <Route path='/' element={<Home />} />

          </Routes>

          </>

        :
          <>
          <Routes>
          <Route path='/home' element={<Home />} />
          {/* <Route path='/sermons' element={<AudioList />} /> */}
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/login' element={<Login />} />
          <Route path='/announcements' element={<Announcement />} />
          <Route path='/thankyou' element={<Thanks />} />
          <Route path='/ministry/discipleship' element={ <Discipleship user={this.state.user} />} />

          <Route path='/ministry/discipleship' element={ <Discipleship user={this.state.user} />} />
            <Route path='/ministry/fellowship' element={ <Fellowship user={this.state.user} />} />
            <Route path='/ministry/lunchfellowship' element={ <LunchFellowship user={this.state.user} />} />
            <Route path='/ministry/sundayschool' element={ <SundaySchool user={this.state.user} />} />
            <Route path='/ministry/sundayworship' element={ <SundayWorship user={this.state.user} />} />
            <Route path='/ministry/welcome' element={ <Welcome user={this.state.user} />} />
            <Route path='/ministry/youthministry' element={ <YouthMinistry user={this.state.user} />} />

            <Route path='/' element={<Home />} />
          </Routes>

          </>
        }
        </Router>

        <div className="footer"> <br />
            <p> &copy; Maryland Chinese Baptist Church ｜ 马利兰华人浸信会 </p>

      </div>

      </div>
    );
  }
}
export default App;
