import React, { Component } from 'react'
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import fire from '../../../fire.js';
import Carousel from 'react-bootstrap/Carousel'


class DisplayPhotos extends Component {

  _isMounted = false;

    state = {
      options: [],
      filenames: [],
    };

  componentDidMount() {
    this._isMounted = true;

      var db = fire.firestore();
      db.collection(this.props.collectionName).get().then(
          (querySnapshot) => {
            if (this._isMounted) {
            querySnapshot.forEach((doc) => {
                this.setState({options: this.state.options.concat( {url: doc.data().url, name: doc.data().name}  )})
             });
           }
        }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {


    let carouselitems = [];
    for (let i in this.state.options)  {
      carouselitems.push(
        <Carousel.Item key ={i}>
          <img
            className="carousel"
            src={this.state.options[i].url}
            alt={this.state.options[i].name}
          />
        </Carousel.Item>
      );

    }

    return (


        <>
          <center>
          <Carousel style = {{height: "30%"}}>
          {carouselitems}
          </Carousel>
          </center>
        </>



    )


  }

}


export default DisplayPhotos;
