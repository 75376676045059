import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"


class SundayWorship extends Component {

  render() {

    return (
      <>

      <div className = "keywords">
      马利兰
      马里兰
      马利兰教会
      马利兰华人教会
      马利兰浸信会
      马利兰华人浸信会
      马里兰教会
      马里兰华人教会
      马里兰浸信会
      马里兰华人浸信会
      华人浸信会
      华人教会
      </div>


      <div className = "sectionPart" id = "c">
      {/* <UploadPhoto storageName = "sundayworship-photos" collectionName = "SundayWorshipPhotos" user={this.props.user}/> */}

      <center> <h3> 主日崇拜：10:30am </h3> </center> 是基督徒一周信仰生活的最重要时刻。 你将和我们在一起以诗歌，祷告，诵读，思想神的话语等方式敬拜主。我们既唱传统诗歌，也唱现代诗歌。你将听到陈牧师以解经讲道的方式，传讲主耶稣基督的福音真理。每年的讲道都会涵盖旧约和新约圣经神的教导，靠着圣灵在我们心中的启示和工作，装备弟兄姐妹，胜过当今世界对我们生活的各样挑战，完成主耶稣托付的大使命。

      {/* <DisplayPhotos collectionName = "SundayWorshipPhotos" /> */}

      </div>



      </>


    )


  }

}


export default SundayWorship;
