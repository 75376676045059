import React, { Component } from 'react'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import UploadPhoto from "./ministry-components/uploadphoto.js"
import DisplayPhotos from "./ministry-components/displayphotos.js"

class SundaySchool extends Component {

  render() {
    return (
      <>

      {/* <UploadPhoto storageName = "sundayschool-photos" collectionName = "SundaySchoolPhotos" user={this.props.user}/> */}



      <div className = "sectionPart" id = "a">
      <center> <h3>  主日学 11:50am </h3> </center>
      <p>
       <strong> 主日学 </strong> 除了主日崇拜之外，主日学是教会系统教导神的话语最主要的管道，你将听到既有资深同工详尽的教导，也有弟兄姐妹积极的参与讨论和分享，我们目前正在学习路加福音中主耶稣的比喻，主耶稣家谱中的女性，传道书，信仰人生等。慕道班随时预备接纳新来未信主的朋友，在Johns Hopkins University做过十年大陆学生学者福音事工的牧师，能够和你讨论任何基督信仰和与之相关的实际问题。
      </p>
      {/* <DisplayPhotos collectionName = "SundaySchoolPhotos" /> */}
      </div>




      <div className = "keywords">
      马利兰
      马里兰
      马利兰教会
      马利兰华人教会
      马利兰浸信会
      马利兰华人浸信会
      马里兰教会
      马里兰华人教会
      马里兰浸信会
      马里兰华人浸信会
      华人浸信会
      华人教会
      </div>


      </>


    )


  }

}


export default SundaySchool;
